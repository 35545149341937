import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Container as Grid, Row, media } from 'styled-bootstrap-grid';
import Imgix from 'react-imgix';
import { Collapse } from 'react-collapse';
import { useTransition, animated } from 'react-spring';
import Carousel from 'react-multi-carousel';
import { useTheme, useBreakpoint } from 'src/hooks';
import { SlideDot as Dot } from 'components/Shared';

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${({ active }) => (active ? '400px' : 0)};

  ${media.lg`
    height: ${({ active }) => (active ? '200px' : 0)};
  `}
`;

const AccordionContainer = styled.div`
  height: 90%;
  .ReactCollapse--collapse {
    transition: height 200ms;
  }
`;

const CarouselContainer = styled.div`
  padding: 32px 0px 16px 0px;
`;

const CarouselImageContainer = styled.div`
  height: 300px;
  padding: 0px 0px 48px 0px;
`;

const AccordionItem = styled.div`
  position: relative;
  padding-left: 16px;
  padding-top: 6px;
  margin-bottom: 16px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background: ${({ theme }) => theme.palette.grey[300]};
    border-radius: 6px;
  }

  &:after {
    content: ${({ active }) => active && '""'};
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: 6px;
  }
`;

const StyledImage = styled(Imgix)`
  position: relative;
  object-fit: contain;
  height: 100%;
  z-index: 1;
  border-radius: 8px;
  display: flex;
  margin: 0;

  ${media.lg`
    max-width: 400px;
  `}
`;

const StyledMobileImage = styled(StyledImage)`
  position: relative;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translateY(-50%) translateX(-50%);
  object-fit: contain;
  width: 100%;
  z-index: 1;
  border-radius: 8px;
  display: flex;
  margin: 0;
`;

const ImageTransition = styled(animated.div)`
  position: relative;
  height: 100%;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  line-height: 31px;
  text-align: left;
  padding: 8px 32px;
  color: ${({ active, theme }) =>
    active ? theme.palette.text.primary : theme.palette.grey[600]};
  cursor: pointer;
`;

const ItemIndex = styled.div`
  background-color: ${({ active, theme }) =>
    active ? theme.palette.text.primary : theme.palette.text.secondary};
  color: ${({ active, theme }) =>
    active ? theme.palette.text.secondary : theme.palette.text.primary};
  padding: 4.5px 14px;
  border-radius: 25px;
  filter: drop-shadow(0px -1px 20px rgba(0, 0, 0, 0.25));
  font-family: Lato;
  font-weight: 600;
  font-size: 22px;
`;

const ItemTitle = styled.h4`
  text-align: left;
  padding: 24px 24px 0px 24px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const ItemText = styled.p`
  text-align: left;
  padding: 16px 32px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const CarouselTitle = styled(ItemTitle)`
  padding: 0px 0px 8px 0px;
  margin: 0px;
`;

const CarouselText = styled(ItemText)`
  padding: 8px 0px 0px 0px;
  margin: 0px;
`;

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { image: { url, alt } = {} } = items[activeIndex];
  const { breakpoints } = useTheme();
  const showCarousel = useBreakpoint('lg');
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: breakpoints.lg },
      items: 1,
    },
    mobile: {
      breakpoint: { max: breakpoints.lg, min: 0 },
      items: 1,
    },
  };

  const transition = useTransition([activeIndex], {
    from: { opacity: 0, position: 'absolute' },
    enter: { opacity: 1, position: 'absolute' },
    leave: { opacity: 0, position: 'absolute' },
    delay: 100, // Delay before first animation
  });

  const fragment = transition((style, item) => {
    return (
      <ImageTransition style={style}>
        <StyledImage
          className="lazyload"
          src={url}
          htmlAttributes={{ alt }}
          sizes="60vw"
          attributeConfig={{
            src: 'data-src',
            srcSet: 'data-srcset',
            sizes: 'data-sizes',
          }}
        />
      </ImageTransition>
    );
  });

  return (
    <Grid>
      <Row>
        <AccordionContainer>
          {items.map(({ image, step_title: title, text }, index) => {
            const active = index === activeIndex;
            return (
              <Row alignItems="center" justify="center">
                <Col col={12} lg={6} hiddenLgDown>
                  <ImageContainer active={active}>{fragment}</ImageContainer>
                </Col>
                <Col col={12} lg={6} hiddenLgDown>
                  <AccordionItem
                    key={index}
                    active={active}
                    onClick={() => setActiveIndex(index)}
                  >
                    <TitleRow>
                      <ItemIndex active={active}>{index + 1}</ItemIndex>
                      <ItemTitle active={active}>{title.text}</ItemTitle>
                    </TitleRow>
                    <Collapse isOpened={active}>
                      <ItemText>{text.text}</ItemText>
                    </Collapse>
                  </AccordionItem>
                </Col>
              </Row>
            );
          })}
        </AccordionContainer>

        <Col col={12} hiddenLgUp>
          {showCarousel && (
            <Carousel
              afterChange={(previousSlide, { currentSlide }) => {
                setActiveIndex(currentSlide);
              }}
              removeArrowOnDeviceType={['tablet', 'mobile']}
              responsive={responsive}
              ssr
              transitionDuration={200}
              showDots={true}
              customDot={<Dot />}
            >
              {items.map(({ image, step_title: title, text }, index) => {
                const active = index === activeIndex;
                return (
                  <CarouselContainer key={index}>
                    <CarouselImageContainer>
                      <StyledMobileImage
                        className="lazyload"
                        src={image.url}
                        sizes="100vw"
                        attributeConfig={{
                          src: 'data-src',
                          srcSet: 'data-srcset',
                          sizes: 'data-sizes',
                        }}
                      />
                    </CarouselImageContainer>
                    <CarouselTitle active={active}>{title.text}</CarouselTitle>
                    <CarouselText>{text.text}</CarouselText>
                  </CarouselContainer>
                );
              })}
            </Carousel>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export default Accordion;
